import React, { memo } from 'react'
import Helmet from 'react-helmet'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Button, Flex, Heading, Text, useBreakpointValue } from '@chakra-ui/react'

const HomePage = memo(() => {
  const headingSize = useBreakpointValue({ base: "4xl", md: "3xl" })
  return (
    <>
      <Helmet>
        <title>Welcome</title>
      </Helmet>
      <Flex align="center" direction="column" justify="center" pb={0} pt={{ base: 12, sm: 20, md: 32 }}>
        <Heading as="h1" fontWeight="light" mb={8} size={headingSize} textAlign="center">Hi, I'm TabsieBot!</Heading>
        <Box maxWidth="420px" textAlign="center">
          <Text fontSize={{ base: "md", sm: "lg" }} fontWeight="semibold" mb={5}>
            I help people keep tabs on alcohol consumption.
          </Text>
          <Text fontSize="sm" mb={2}>
            Each morning, I'll ask a quick question about the day before, and share a snapshot of your progress over time.
          </Text>
          <Text fontSize="sm" mb={8}>
            Yep. It's that simple.
          </Text>
          <Button
            as={RouterLink}
            borderRadius="full"
            px={6}
            to="/signup"
            variant="outline"
          >
            Let's get started
          </Button>
        </Box>
      </Flex>
    </>
  )
})

export default HomePage
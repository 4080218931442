/**
 * Utility Functions
 */

export function formatTimezoneOffset (mins) {
  const h = Math.floor(Math.abs(mins) / 60)
  const m = Math.floor((Math.abs(mins) % 60) / 60)
  const prefix = mins > 0 ? '+' : '-'
  const result = [
    h > 9 ? h : '0' + h,
    m > 9 ? m : (h ? '0' + m : m || '0')
  ].filter(Boolean).join(':')
  return prefix + result
}

export function guessTimezone () {
  let result = ''
  try {
    result = Intl.DateTimeFormat().resolvedOptions().timeZone
  }
  catch (e) {}
  return result
}

import sc from 'states-cities-db'

export const COUNTRIES = sc.getCountries()

export const ALLOWED = [
  {
    "name": "United States",
    "slug": "united-states",
    "iso": "USA",
    "iso2": "US",
    "prefix": "+1",
    "currency": "USD",
    "region": "Americas",
    "subregion": "Northern America",
    "latlng": [
        38,
        -97
    ]
  },
  {
    "name": "Canada",
    "slug": "canada",
    "iso": "CAN",
    "iso2": "CA",
    "prefix": "+1",
    "currency": "CAD",
    "region": "Americas",
    "subregion": "Northern America",
    "latlng": [
        60,
        -95
    ]
  }
]

export const getCountry = code => {
  const match = code && ALLOWED.find(({ iso2 }) => iso2 === code)
  return match || {}
}
import React, { memo, useCallback } from 'react'
import { Formik, Form, Field } from 'formik'

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input
} from '@chakra-ui/react'

import { PhoneNumberInput, TimezoneSelect } from 'components/elements'

export const UserAccountForm = memo(({ onSubmit, values }) => {
  const {
    email = '',
    mobile = ''
  } = values

  let initialValues = {
    email,
    mobile
  }

  const onFormSubmit = useCallback((values, { setSubmitting }) => {
    setSubmitting(false)
    onSubmit(values)
  }, [onSubmit])

  const onValidate = useCallback(values => {
    const errors = {}
    if (!values.email) {
      errors.email = 'Email address is required'
    }
    if (!values.mobile) {
      errors.mobile = 'Mobile number is required'
    }
    else {
      if (values.mobile.split('+').length - 1 > 1) {
        errors.mobile = 'Country code is already included'
      }
    }
    return errors
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validate={onValidate}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>
          {/* Email Address */}
          <Field name="email">
            {({ field, form }) => (
              <FormControl
                isDisabled={isSubmitting}
                isInvalid={form.errors.email && form.touched.email}
                isRequired={true}
                mb={4}
              >
                <FormLabel>
                  Email Address
                </FormLabel>
                <Input
                  {...field}
                  placeholder="example@address.com"
                />
                <FormErrorMessage>
                  {form.errors.email}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          {/* Mobile Number */}
          <Field name="mobile">
            {({ field, form }) => (
              <FormControl
                isDisabled={isSubmitting}
                isInvalid={form.errors.mobile && form.touched.mobile}
                isRequired={true}
                mb={4}
              >
                <FormLabel>
                  Mobile Number
                </FormLabel>
                <PhoneNumberInput
                  {...field}
                  country="US"
                  onChange={value => {
                    setFieldValue('mobile', value)
                  }}
                />               
                <FormHelperText>
                  Device that can send and receive text messages.
                </FormHelperText>
                <FormErrorMessage>
                  {form.errors.mobile}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Button
            isDisabled={isSubmitting}
            mt={4}
            type="submit"
            variant="outline"
          >
            Update
          </Button>
        </Form>
      )}
    </Formik>
  )
})
import React, { memo } from 'react'
import { 
  ChakraProvider,
  ColorModeScript,
  extendTheme,
  withDefaultColorScheme
} from '@chakra-ui/react'
import defaultTheme from '@chakra-ui/theme'
import { transparentize } from '@chakra-ui/theme-tools'

import { colors } from './colors'
import { components } from './components'
import { 
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings
} from './typography'
import { shadows } from './shadows'
import { space } from './space'
import { styles } from './styles'

export const theme = extendTheme(
  {
    config: {
      // initialColorMode: 'dark',
      useSystemColorMode: true
    },
    colors: {
      ...colors,
      primary: defaultTheme.colors.teal
    },
    components,
    fontSizes,
    fontWeights,
    lineHeights,
    letterSpacings,
    shadows: {
      ...shadows,
      outline: `0 0 0 3px ${transparentize(defaultTheme.colors.teal['400'], 0.24)(defaultTheme)}`
    },
    space,
    styles
  },
  withDefaultColorScheme({ colorScheme: 'primary' })
)
// console.log('theme', theme)

export const ThemeProvider = memo(({ children }) => (
  <ChakraProvider resetCSS theme={theme}>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    {children}
  </ChakraProvider>
))

import React, { memo, useCallback } from 'react'
import { Formik, Form, Field } from 'formik'
import { Link as RouterLink } from 'react-router-dom'

import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link
} from '@chakra-ui/react'

export const LoginForm = memo(({ onSubmit, values }) => {
  const { 
    email = '',
    password = '',
    remember_me = false
  } = values

  let initialValues = {
    email,
    password,
    remember_me
  }

  const onFormSubmit = useCallback((values, { setSubmitting }) => {
    setSubmitting(false)
    onSubmit(values)
  }, [onSubmit])

  const onValidate = useCallback(values => {
    const errors = {}
    if (!values.email) {
      errors.email = 'Email address is required'
    }
    if (!values.password) {
      errors.password = 'Password is required'
    }
    return errors
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validate={onValidate}
    >
      {({ isSubmitting }) => (
        <Form>

          {/* Email Address */}
          <Field name="email">
            {({ field, form }) => (
              <FormControl
                isDisabled={isSubmitting}
                isInvalid={form.errors.email && form.touched.email}
                isRequired={true}
                mb={4}
              >
                <FormLabel>
                  Email Address
                </FormLabel>
                <Input
                  {...field}
                  placeholder="example@address.com"
                />
                <FormErrorMessage>
                  {form.errors.email}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          {/* Password */}
          <Field name="password">
            {({ field, form }) => (
              <FormControl
                isDisabled={isSubmitting}
                isInvalid={
                  form.errors.password &&
                  (form.touched.password || form.touched.password_confirmation)
                }
                isRequired={true}
                mb={4}
              >
                <FormLabel>
                  Password
                </FormLabel>
                <Input
                  {...field}
                  placeholder="••••••••••••"
                  type="password"
                />
                <FormErrorMessage>
                  {form.errors.password}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          {/* Remember Me */}
          <Field name="remember_me">
            {({ field, form }) => (
              <FormControl
                isDisabled={isSubmitting}
                isInvalid={form.errors.remember_me && form.touched.remember_me}
                isRequired={false}
                mb={8}
              >
                <Flex align="center" justify="space-between">
                  <Flex align="flex-start">
                    <Checkbox
                      {...field}
                      fontSize="base"
                      h={6}
                      id="toggle-remember-me"
                      isChecked={field.value}
                      isDisabled={isSubmitting}
                      mb={0}
                      mr={3}
                    />
                    <FormLabel
                      fontWeight={400}
                      htmlFor="toggle-remember-me"
                      lineHeight={6}
                      mb={0}
                    >
                      Remember me
                    </FormLabel>
                  </Flex>
                  <Link
                    as={RouterLink}
                    fontSize="sm"
                    to="/password/recovery"
                  >
                    Forgot Password?
                  </Link>
                </Flex>
                <FormErrorMessage>
                  {form.errors.remember_me}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Button
            isDisabled={isSubmitting}
            type="submit"
          >
            Log in
          </Button>
        </Form>
      )}
    </Formik>
  )
})

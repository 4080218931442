import React, { memo, useCallback, useEffect } from 'react'
import { Link as RouterLink, Route, Switch, useLocation } from 'react-router-dom'

import { 
  Box,
  Button,
  Fade,
  Flex,
  HStack,
  IconButton,
  Link,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react'
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'

import { useAuth } from 'components/api'
import { Logomark } from 'components/elements'

const routes = [
  { label: 'Dashboard', path: '/dashboard' },
  { label: 'Settings', path: '/settings' }
]

const mobileRoutes = [
  { label: 'Home', path: '/' }
].concat(routes)

const DesktopNav = memo(() => {
  return (
    <HStack spacing={4}>
      {routes.map((route, index) => (
        <Link
          as={RouterLink}
          fontWeight="medium"
          key={index}
          to={route.path}
        >
          {route.label}
        </Link>
      ))}
    </HStack>
  )
})

const MobileNav = memo(({ isOpen, onClose }) => {
  const bgColor = useColorModeValue('white', 'gray.900')

  const onClick = useCallback(() => {
    onClose()
  }, [onClose])

  useEffect(() => {
    const classKey = 'prevent-scroll'
    document.body.classList.add(classKey)
    return () => {
      document.body.classList.remove(classKey)
    }
  }, [])

  return (
    <Flex
      align="center"
      bgColor={bgColor}
      bottom={0}
      direction="column"
      height="100vh"
      justify="center"
      left={0}
      p={10}
      position="fixed"
      right={0}
      top={0}
      zIndex={2}
    >
      <Fade in={isOpen}>
        {mobileRoutes.map((route, index) => (
          <Link
            as={RouterLink}
            display="block"
            fontFamily="heading"
            fontSize="3xl"
            fontWeight="bold"
            key={index}
            lineHeight={8}
            onClick={onClick}
            py={6}
            textAlign="center"
            to={route.path}
          >
            {route.label}
          </Link>
        ))}
      </Fade>    
    </Flex>
  )
})

export const Header = memo(() => {
  const { currentUser } = useAuth()
  const { pathname } = useLocation()
  const { isOpen, onClose, onToggle } = useDisclosure()
  const logoColor = useColorModeValue('gray.800', 'white')
  const viewport = useBreakpointValue({ base: 'mobile', sm: 'desktop' })

  useEffect(() => {
    if (isOpen) {
      onClose()
    }
  }, [pathname])

  return (
    <>
      {viewport === 'mobile' && isOpen && (
        <MobileNav isOpen={isOpen} onClose={onClose} />
      )}

      <Flex fontSize="sm" justify="space-between" py={7}>
        <Link
          as={RouterLink}
          color={logoColor}
          display="flex"
          alignItems="center"
          fontSize="xl"
          fontWeight="semibold"
          lineHeight={10}
          to="/"
          _hover={{ textDecoration: 'none' }}
        >
          <Logomark boxSize={8} mr="6px" mt={-1} />
          TabsieBot
        </Link>
        {currentUser && currentUser.id ? (
          viewport === 'mobile' ? (
            <IconButton
              aria-label={`Site Navigation`}
              icon={
                isOpen ? <CloseIcon boxSize={5} /> : <HamburgerIcon boxSize={5} />
              }
              onClick={onToggle}
              variant="outline"
              zIndex={3}
            />
          ) : (
            <DesktopNav />
          )
          
        ) : (
          <Box>
            <Switch>
              <Route
                exact={true}
                path="/signup"
              >
                {viewport !== 'mobile' && (
                  <Text as="span" fontSize="sm" mr={3}>Already have an account?</Text>
                )}
                <Button as={RouterLink} to="/login" variant="outline">Log in</Button>
              </Route>
              <Route
                exact={true}
                path="/login"
              >
                {viewport !== 'mobile' && (
                  <Text as="span" fontSize="sm" mr={3}>Ready to get started?</Text>
                )}
                <Button as={RouterLink} to="/signup" variant="outline">Sign up</Button>
              </Route>
              <Route>
                <Button as={RouterLink} mr={2} to="/login" variant="ghost">Log in</Button>
                <Button as={RouterLink} to="/signup" variant="outline">Sign up</Button>
              </Route>
            </Switch>
          </Box>
        )}
      </Flex>
    </>
  )
})
import React, { memo, useMemo } from 'react'
import { format } from 'date-fns'

import { 
  Box,
  useBreakpointValue,
  useColorModeValue,
  useToken
} from '@chakra-ui/react'
import { ResponsiveBar } from '@nivo/bar'

function randomize(data) {
  return data.map(d => (
    { 
      ...d, 
      value: Math.floor(Math.random() * (5 - 0)) + 0
    }
  ))
}

export const BarChart = memo(({ data }) => {
  const [gray200, gray300, gray500, gray700] = useToken('colors', ['gray.200', 'gray.300', 'gray.500', 'gray.700'])
  const [primary400, primary500] = useToken('colors', ['pink.400', 'pink.500'])
  const barColor = useColorModeValue(primary500, primary400)
  const strokeColor = useColorModeValue(gray200, gray700)
  const textColor = useColorModeValue(gray500, gray300)
  const barPadding = useBreakpointValue({ base: 0.2, sm: 0.12 })

  const axisLabels = useMemo(() => (
    data
      .slice()
      .reverse()
      .map((v,i) => (
        (i % 5 !== 0) ? '' : v.timestamp
      ))
      .reverse()
  ), [data])

  const yAxisMax = useMemo(() => {
    const values = data.slice().map(v => v.value)
    return Math.max(...values)
  }, [data])

  return (
    <Box h="240px" mb={4}>
      <ResponsiveBar
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={(d) => (
          `${format(new Date(d.indexValue), 'MMM d')}: ${d.formattedValue}`
        )}

        data={data}
        // data={randomize(data)}
        keys={[ "value" ]}
        indexBy="timestamp"

        axisBottom={{
          format: (d) => (
            axisLabels.find(label => label === d) ? format(new Date(d), 'MMM d') : ''
          ),
          tickPadding: 4,
          tickSize: 6
        }}
        axisLeft={{
          tickValues: yAxisMax >= 6 ? Math.round(yAxisMax / 2) : yAxisMax
        }}
        axisTop={null}
        axisRight={null}

        borderRadius={2}
        
        enableGridX={false}
        enableGridY={true}
        enableLabel={false}

        isFocusable={true}
        isInteractive={false}

        legends={[]}
        margin={{ top: 16, right: 8, bottom: 32, left: 28 }}
        minValue={0}
        padding={barPadding}

        colors={[barColor]}
        theme={{
          axis: {
            ticks: {
              line: {
                stroke: strokeColor
              },
              text: {
                fill: textColor
              }
            }
          },
          grid: {
            line: {
              stroke: strokeColor,
              strokeWidth: 1
            }
          }
        }}
      />
    </Box>
  )
})
export const colors = {
  none: 'transparent',
  black: '#15171A',
  white: '#FFFFFF',
  gray: {
    '50': '#F2F4F5',
    '100': '#F2F4F5',
    '200': '#D6DADE',
    '300': '#BBC1C7',
    '400': '#9FA7B0',
    '500': '#6D7782',
    '600': '#4D565E',
    '700': '#31363A',
    '800': '#24272B',
    '900': '#15171A',
  }
}
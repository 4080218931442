import axios from 'axios'

const api = axios.create({
  baseURL: '/api/v1',
  timeout: 1000 * 60 * 5,
  headers: {
    'Content-Type': 'application/json'
  }
})

api.interceptors.request.use(function (config) {
  config.headers['access-token'] = localStorage.getItem('auth-access-token') || ''
  config.headers['client'] = localStorage.getItem('auth-client') || ''
  config.headers['expiry'] = localStorage.getItem('auth-expiry') || ''
  config.headers['token-type'] = localStorage.getItem('auth-token-type') || ''
  config.headers['uid'] = localStorage.getItem('auth-uid') || ''
  return config
})

export { api }
import React, { createContext, useContext, useMemo } from 'react'
import Plausible from 'plausible-tracker'

const AnalyticsContext = createContext()
AnalyticsContext.displayName = 'AnalyticsContext'

function AnalyticsProvider(props) {
  const value = useMemo(() => (
    Plausible({
      domain: process.env['PLAUSIBLE_API_HOST'],
      domain: process.env['PLAUSIBLE_DOMAIN'],
      trackLocalhost: process.env.NODE_ENV === 'development'
    })
  ), [])

  return (
    <AnalyticsContext.Provider value={value} {...props} />
  )
}

function useAnalytics() {
  const context = useContext(AnalyticsContext)
  if (context === undefined) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider')
  }
  return context
}

export {
  AnalyticsProvider,
  useAnalytics
}
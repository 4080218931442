import { 
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query'
import { api } from './client'
import { useAnalytics } from './analytics-context'

const FETCH_TABS_KEY = 'fetch-tabs'

export const useFetchTabs = () => {
  return useQuery(
    FETCH_TABS_KEY,
    async () => {
      const response = await api.get(`/tabs`)
      return response?.data
    }
  )
}

export const useUpdateTab = (id) => {
  const queryClient = useQueryClient()
  const { trackEvent } = useAnalytics()
  return useMutation(
    async (payload) => {
      const response = await api.put(`/tabs/${id}`, payload)
      return response?.data
    }, 
    {
      onSuccess: () => {
        trackEvent('tab-update')
        return queryClient.invalidateQueries(FETCH_TABS_KEY)
      },
      onError: () => {
        trackEvent('error', { props: { event: 'tab-update' } })
      }
    }
  )
}
import React, { memo, useCallback, useEffect, useState } from 'react'
import {
  Flex,
  InputAddon,
  InputGroup,
  Select
} from '@chakra-ui/react'

export const InquiryIntervalInput = memo(({ onChange, value, ...rest }) => {
  const [hour, setHour] = useState()
  const [min, setMin] = useState()
  const [meridian, setMeridian] = useState('AM')

  const onChangeHour = useCallback(e => {
    const { value } = e.target
    setHour(parseInt(value))
  }, [])

  const onChangeMin = useCallback(e => {
    const { value } = e.target
    setMin(parseInt(value))
  }, [])

  const onChangeMeridian = useCallback(e => {
    const { value } = e.target
    setMeridian(value)
  }, [])

  useEffect(() => {
    const parsedHour = parseInt(value.split(':')[0])
    const parsedMin = parseInt(value.split(':')[1])
    const offset = parsedHour >= 12 ? 12 : 0
    const offsetHour = parsedHour - offset
    if (hour !== offsetHour) {
      setHour(offsetHour)
    }
    if (min !== parsedMin) {
      setMin(parsedMin)
    }
    if (meridian !== 'PM' && offset > 0) {
      setMeridian('PM')
    }
  }, [value])

  useEffect(() => {
    if (meridian && typeof hour === 'number' && typeof min === 'number') {
      const offset = meridian === 'AM' ? 0 : 12
      const padHour = String(offset + hour).padStart(2, '0')
      const padMin = String(min).padStart(2, '0')
      const result = `${padHour}:${padMin}`
      if (result !== value) {
        onChange(result)
      }
    }
  }, [hour, min, meridian])

  return (
    <Flex justify="space-between" {...rest}>
      <InputGroup flex={2}>
        <Select
          borderBottomRightRadius={0}
          borderTopRightRadius={0}
          onChange={onChangeHour}
          textAlign="center"
          value={hour}
        >
          {Array.apply(null, Array(12)).map((v, i) => (
            <option
              key={`opt-${i}`}
              value={i === 11 ? 0 : i+1}
            >
              {String(i === 11 ? 12 : i+1).padStart(2, '0')}
            </option>
          ))}
        </Select>

        <InputAddon 
          borderRadius={0}
          borderLeftWidth={0}
          children=":"
          mr={0}
        />

        <Select
          borderBottomLeftRadius={0}
          borderTopLeftRadius={0}
          id="inquiry-minutes-field"
          onChange={onChangeMin}
          textAlign="center"
          value={min}
        >
          <option value={0}>00</option>
          <option value={30}>30</option>
        </Select>

      </InputGroup>

      <Select
        flex={1}
        id="inquiry-meridian-field"
        ml={2}
        onChange={onChangeMeridian}
        value={meridian}
      >
        <option value="AM">AM</option>
        <option value="PM">PM</option>
      </Select>
    </Flex>
  )
})
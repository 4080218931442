import React, { memo } from 'react'

import App from './pages'
import ApiProvider from './api'
import Router from './router'
import { ThemeProvider } from './theme'

export default memo(({ location, ...rest }) => {
  return (
    <ApiProvider>
      <Router location={location}>
        <ThemeProvider>
          <App {...rest} />
        </ThemeProvider>
      </Router>
    </ApiProvider>
  )
})
import React, { memo } from 'react'
import { 
  Center,
  Spinner as ChakraSpinner,
  useColorModeValue,
  useToken
} from '@chakra-ui/react'

export const Spinner = memo(({ py = 16 }) => {
  const [gray200, gray600] = useToken('colors', ['gray.200', 'gray.600'])
  const color = useColorModeValue(gray200, gray600)
  return (
    <Center py={py}>
      <ChakraSpinner
        color={color}
        size="xl"
        speed="0.68s"
        thickness="2px"
      />
    </Center>
  )
})
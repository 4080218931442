import React, { memo } from 'react'
import { Icon, useColorModeValue, useToken } from '@chakra-ui/react'

export const Logomark = memo(({ color, ...rest } )=> {
  const [primary400, primary500, primary600] = useToken('colors', ['primary.400', 'primary.500', 'primary.600'])
  const fillA = useColorModeValue(primary500, primary400)
  const fillB = useColorModeValue(primary600, primary400)

  return (
    <Icon color={color} viewBox="0 0 700 700" {...rest}>
      <path fill={color ? 'currentColor' : fillA} fillRule="evenodd" clipRule="evenodd" d="M667.635 315.186L684.574 334.501C686.546 336.749 687.38 339.772 686.838 342.71L662.151 476.751C661.641 479.523 659.957 481.94 657.531 483.383L643.826 491.54C639.176 494.308 633.158 492.79 630.384 488.15C629.253 486.258 628.795 484.039 629.085 481.855L650.539 320.342C651.25 314.986 656.178 311.22 661.545 311.93C663.904 312.242 666.069 313.399 667.635 315.186Z" />
      <path fill={color ? 'currentColor' : fillA} fillRule="evenodd" clipRule="evenodd" d="M25.0153 347.081L16.2163 355.63C13.9053 357.875 12.7399 361.05 13.049 364.259L23.9347 477.276C24.2025 480.056 25.5556 482.619 27.7002 484.407L35.0193 490.511C37.6012 492.664 41.4383 492.314 43.5897 489.731C44.568 488.556 45.0687 487.056 44.9924 485.528L38.3375 352.33C38.1208 347.992 34.4313 344.652 30.0968 344.869C28.1907 344.964 26.3845 345.75 25.0153 347.081Z" />
      <path fill={color ? 'currentColor' : fillA} fillRule="evenodd" clipRule="evenodd" d="M308.675 31.7762C327.511 24.9362 346.215 27.6814 353.229 37.6427C363.07 49.4406 371.341 62.7234 378.04 77.4906C385.158 93.1804 392.151 113.619 399.02 138.807L382.553 146.042C381.862 121.925 377.966 100.917 370.865 83.0188C365.118 68.5326 361.657 58.9947 354.482 54.4051C350.59 63.8935 339.932 73.2219 325.683 78.3964C304.714 86.0107 283.909 81.7469 279.213 68.8731C274.516 55.9993 287.707 39.3904 308.675 31.7762Z" />
      <path fill={color ? 'currentColor' : fillB} fillRule="evenodd" clipRule="evenodd" d="M638.234 122.231C638.281 122.813 638.303 123.397 638.3 123.982C637.921 199.794 634.692 273.746 628.612 345.837C628.216 350.522 627.809 355.201 627.389 359.874L571.871 362.895C571.974 360.908 572.027 358.907 572.027 356.894C572.027 294.102 521.007 243.199 458.07 243.199C395.134 243.199 344.114 294.102 344.114 356.894C344.114 363.129 344.617 369.247 345.585 375.21L232.123 381.384C232.194 380.12 232.229 378.847 232.229 377.566C232.229 340.461 202.081 310.382 164.892 310.382C127.702 310.382 97.5537 340.461 97.5537 377.566C97.5537 381.344 97.8663 385.05 98.4672 388.658L54.1799 391.068C54.133 389.722 54.0874 388.373 54.0431 387.021C52.3107 334.117 52.6796 281.455 55.1499 229.037L55.3733 224.412C55.7941 215.912 61.3929 208.538 69.4801 205.832C127.598 186.388 209.21 166.352 314.316 145.722C419.521 125.073 520.058 110.923 615.929 103.273C627.335 102.363 637.322 110.85 638.234 122.231Z" />
      <path fill={color ? 'currentColor' : fillA} fillRule="evenodd" clipRule="evenodd" d="M65.4468 530.76C60.3123 493.389 56.7586 451.99 54.7859 406.562L102.941 403.941C113.217 427.935 137.086 444.749 164.892 444.749C195.285 444.749 220.976 424.659 229.35 397.062L349.187 390.541C363.564 436.906 406.875 470.589 458.07 470.589C513.593 470.589 559.841 430.972 569.967 378.526L625.941 375.479C620.042 437.002 612.023 497.372 601.885 556.589L601.13 560.972C599.446 570.699 591.105 577.88 581.214 578.119C531.384 579.322 484.358 579.892 440.134 579.829C431.577 600.003 420.289 618.025 406.272 633.895C393.984 647.808 379.596 660.07 363.11 670.68C361.771 671.542 360.211 672 358.617 672C354.04 672 350.33 668.298 350.33 663.731L350.329 578.742C338.064 578.449 326.06 578.098 314.316 577.688C238.016 575.025 161.437 565.398 84.5805 548.806L81.5466 548.147C73.0561 546.294 66.6272 539.351 65.4468 530.76ZM302.17 469.253C286.249 470.179 267.425 468.901 245.697 465.421C250.532 474.378 257.438 483.336 266.416 492.294C279.884 505.731 301.134 511.932 325.466 503.663C341.688 498.151 351.357 483.336 354.474 459.219C335.526 464.983 318.092 468.328 302.17 469.253Z" />
    </Icon>
  )
})
import React, { memo } from 'react'
import Helmet from 'react-helmet'
import { Link as RouterLink } from 'react-router-dom'

import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react'
import { useAuth } from 'components/api'

const NotFoundPage = memo(() => {
  const { currentUser } = useAuth()
  return (
    <>
      <Helmet>
        <title>404</title>
      </Helmet>
      <Flex align="center" direction="column" py={24}>
        <Heading as="h1" fontWeight="light" mb={8} size="3xl" textAlign="center">
          404
        </Heading>
        <Box maxWidth="420px" textAlign="center">
          <Text fontSize={{ base: "md", sm: "lg" }} fontWeight="semibold" mb={8}>
            Oops! Nothing here.
          </Text>
          {currentUser?.id ? (
            <Button as={RouterLink} to="/dashboard" variant="outline">Back to the dashboard</Button>
          ) : (
            <Button as={RouterLink} to="/" variant="outline">Let's go home</Button>
          )}
        </Box>
      </Flex>
    </>
  )
})

export default NotFoundPage
import React, { memo, useCallback, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { Link as RouterLink } from 'react-router-dom'

import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  Link
} from '@chakra-ui/react'

import { FiGlobe } from 'react-icons/fi'

import { PhoneNumberInput, TimezoneSelect } from 'components/elements'

export const SignupForm = memo(({ onSubmit, values }) => {
  const [ editingTimezone, setEditingTimezone ] = useState(false)
  const [ legalAccepted, setLegalAccepted ] = useState(false)

  const {
    email = '',
    mobile = '',
    password = '',
    password_confirmation = '',
    timezone = 'America/Los_Angeles'
  } = values

  let initialValues = {
    email,
    mobile,
    password,
    password_confirmation,
    timezone
  }

  const onEditTimezone = useCallback(() => {
    setEditingTimezone(true)
  }, [])

  const onFormSubmit = useCallback((values, { setSubmitting }) => {
    setSubmitting(false)
    onSubmit(values)
  }, [onSubmit])

  const onValidate = useCallback(values => {
    const errors = {}
    if (!values.email) {
      errors.email = 'Email address is required'
    }
    if (!values.mobile) {
      errors.mobile = 'Mobile number is required'
    }
    else {
      if (values.mobile.split('+').length - 1 > 1) {
        errors.mobile = 'Country code is already included'
      }
    }
    if (!values.password) {
      errors.password = 'Password is required'
    }
    if (values.password && values.password.length < 6) {
      errors.password = 'Passwords must contain at least 6 characters'
    }
    if (!values.password_confirmation) {
      errors.password_confirmation = 'Password confirmation is required'
    } else if (values.password_confirmation !== values.password) {
      errors.password_confirmation = 'Passwords must match'
    }
    return errors
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validate={onValidate}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>

          {/* Mobile Number */}
          <Field name="mobile">
            {({ field, form }) => (
              <FormControl
                isDisabled={isSubmitting}
                isInvalid={form.errors.mobile && form.touched.mobile}
                isRequired={true}
                mb={4}
              >
                <FormLabel>
                  Mobile Number
                </FormLabel>
                <PhoneNumberInput
                  {...field}
                  country="US"
                  onChange={value => {
                    setFieldValue('mobile', value)
                  }}
                />               
                <FormHelperText fontSize="xs">
                  Device that can send and receive text messages. Carrier data and text messaging rates may apply.
                </FormHelperText>
                <FormErrorMessage>
                  {form.errors.mobile}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
          
          {/* Timezone */}
          <Field name="timezone">
            {({ field, form }) => (
              <FormControl
                isDisabled={isSubmitting}
                isInvalid={form.errors.timezone && form.touched.timezone}
                isRequired={false}
                mb={4}
              >
                {!editingTimezone ? (
                  <Button alignItems="center" lineHeight={5} onClick={onEditTimezone} size="sm" variant="link">
                    <Icon as={FiGlobe} mr={2} />
                    {field.value}
                  </Button>
                ) : (
                  <>
                    <FormLabel>
                      Timezone
                    </FormLabel>
                    <TimezoneSelect
                      {...field}
                      onChange={e => {
                        setFieldValue('timezone', e.target.value)
                        setEditingTimezone(false)
                      }}
                    />
                    <FormErrorMessage>
                      {form.errors.timezone}
                    </FormErrorMessage>
                  </>
                )}
              </FormControl>
            )}
          </Field>

          <Box as="hr" />

          {/* Email Address */}
          <Field name="email">
            {({ field, form }) => (
              <FormControl
                isDisabled={isSubmitting}
                isInvalid={form.errors.email && form.touched.email}
                isRequired={true}
                mb={4}
              >
                <FormLabel>
                  Email Address
                </FormLabel>
                <Input
                  {...field}
                  placeholder="example@address.com"
                />
                <FormErrorMessage>
                  {form.errors.email}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          {/* Password */}
          <Field name="password">
            {({ field, form }) => (
              <FormControl
                isDisabled={isSubmitting}
                isInvalid={
                  form.errors.password &&
                    (form.touched.password ||
                      form.touched.password_confirmation)
                }
                isRequired={true}
                mb={4}
              >
                <FormLabel>
                  Password
                </FormLabel>
                <Input
                  {...field}
                  placeholder="••••••••••••"
                  type="password"
                />
                <FormErrorMessage>
                  {form.errors.password}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          {/* Password Confirmation */}
          <Field name="password_confirmation">
            {({ field, form }) => (
              <FormControl
                isDisabled={isSubmitting}
                isInvalid={
                  form.errors.password_confirmation &&
                  form.touched.password_confirmation
                }
                isRequired={true}
                mb={4}
              >
                <FormLabel>
                  Confirm Password
                </FormLabel>
                <Input
                  {...field}
                  placeholder="••••••••••••"
                  type="password"
                />
                <FormErrorMessage>
                  {form.errors.password_confirmation}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <FormControl
            isDisabled={isSubmitting}
            isRequired={true}
            mb={8}
          >
            <Flex align="flex-start">
              <Checkbox
                fontSize="base"
                h={6}
                id="toggle-accept-legal"
                isChecked={legalAccepted}
                isDisabled={isSubmitting}
                mb={0}
                mr={3}
                onChange={(e) => setLegalAccepted(e.target.checked)}
              />
              <FormLabel
                fontSize="sm"
                fontWeight={400}
                htmlFor="toggle-accept-legal"
                lineHeight={6}
                mb={0}
              >
                {`I have read and accept the `}
                <Link as={RouterLink} fontWeight="semibold" textDecoration="underline" to="/terms">Terms of Service</Link>
                {` and `}
                <Link as={RouterLink} fontWeight="semibold" textDecoration="underline" to="/privacy">Privacy Policy</Link>
                {`.`}
              </FormLabel>
            </Flex>
          </FormControl>

          <Button
            isDisabled={isSubmitting || !legalAccepted}
            type="submit"
            variant="solid"
            w="full"
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  )
})
import { 
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query'
import { api } from './client'
import { useAnalytics } from './analytics-context'

const FETCH_TAB_RECORDS_KEY = 'fetch-tab-records'

export const useFetchTabRecords = (tabId) => {
  return useQuery(
    `${FETCH_TAB_RECORDS_KEY}-${tabId}`,
    async () => {
      const response = await api.get(`/tabs/${tabId}/records`)
      return response?.data
    },
    {
      enabled: !!tabId
    }
  )
}

export const useUpdateTabRecord = (tabId, tabRecordId) => {
  const queryClient = useQueryClient()
  const { trackEvent } = useAnalytics()
  return useMutation(
    async (payload) => {
      const response = await api.put(`/tabs/${tabId}/records/${tabRecordId}`, payload)
      return response?.data
    },
    {
      onSuccess: () => {
        trackEvent('tab-record-update')
        return queryClient.invalidateQueries(`${FETCH_TAB_RECORDS_KEY}-${tabId}`)
      },
      onError: () => {
        trackEvent('error', { props: { event: 'tab-record-update' } })
      }
    }
  )
}
import React, { memo } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AnalyticsProvider } from './analytics-context'
import { AuthProvider } from './auth-context'

export * from './client'
export * from './tab-records'
export * from './tabs'
export * from './users'
export { useAnalytics } from './analytics-context'
export { useAuth } from './auth-context'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 15,
    }
  }
})

export default memo(props => {
  return (
    <AnalyticsProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider {...props} />
      </QueryClientProvider>
    </AnalyticsProvider>
  )
})
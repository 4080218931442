import React, { Children, cloneElement, memo } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAuth } from 'components/api'

const PrivateRoute = memo(({ children, ...rest }) => {
  const { isAuthenticated, isLoading } = useAuth()
  return (
    <Route
      {...rest}
      render={(renderProps) => (
        isAuthenticated ? (
          Children.map(children, child => (
            cloneElement(child, { ...renderProps })
          ))
        ) : 
        isLoading ? (
          <></>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: renderProps.location }
            }}
          />
        )
      )}
    />
  )
})

export { PrivateRoute }
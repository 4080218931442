import React, { memo, useCallback, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { Route, Switch } from 'react-router-dom'

import {
  Box,
  Flex,
  useToast
} from '@chakra-ui/react'

import { useAnalytics } from 'components/api'
import { Footer, Header, PrivateRoute } from 'components/elements'

import DashboardPage from './dashboard'
import HomePage from './home'
import LoginPage from './login'
import LogoutPage from './logout'
import NotFoundPage from './not-found'
import PasswordPage from './password'
import PrivacyPage from './privacy'
import SettingsPage from './settings'
import SignupPage from './signup'
import TermsPage from './terms'

const App = memo(({ flash = [] }) => {
  const [colorMode, setColorMode] = useState('light')
  const { enableAutoOutboundTracking, enableAutoPageviews } = useAnalytics()
  const toast = useToast()

  const onColorModeChange = useCallback(({ matches }) => {
    setColorMode(matches ? 'dark' : 'light')
  }, [])

  useEffect(() => {
    enableAutoOutboundTracking()
    enableAutoPageviews()

    if (!flash || !flash[0]) return
    flash.forEach(f => toast({
      duration: 5000,
      isClosable: true,
      position: 'top',
      title: f[1],
      status: f[0] === 'alert' ? 'error' : 'info'
    }))
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const mq = window.matchMedia('(prefers-color-scheme: dark)')
      mq.addEventListener('change', onColorModeChange)
      onColorModeChange(mq)
    }
    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', onColorModeChange)
    }
  }, [])

  const APP_BASE_URL = process.env.APP_BASE_URL
  const pageTitle = `TabsieBot`
  const pageDesc = `A friendly little bot that helps keep tabs on alcohol consumption.`

  return (
    <>
      <Helmet
        defaultTitle={pageTitle}
        titleTemplate={`%s • ${pageTitle}`}
      >
        <meta name="description" content={pageDesc} />

        {/* Favicons */}
        <link rel="shortcut icon" href={`/images/icons/${colorMode}/favicon.ico`} />
        <link rel="icon" href={`/images/icons/${colorMode}/favicon-32x32.png`} sizes="32x32" type="image/png" />
        <link rel="icon" href={`/images/icons/${colorMode}/favicon-16x16.png`} sizes="16x16" type="image/png" />

        {/* Open Graph */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDesc} />
        <meta property="og:image" content={`${APP_BASE_URL}/images/tabsiebot-preview.png`} />

        {/* Platforms */}
        <link rel="apple-touch-icon" sizes="180x180" href="/images/icons/apple-touch-icon.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/images/icons/safari-pinned-tab.svg" color="#2C7A7B" />
        <meta name="msapplication-TileColor" content="#319795" />
        <meta name="msapplication-config" content="/browserconfig.xml"></meta>
        <meta name="theme-color" content="#FFFFFF" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@tabsiebot" />
        <meta name="twitter:creator" content="@tabsiebot" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDesc} />
        <meta name="twitter:image" content={`${APP_BASE_URL}/images/tabsiebot-preview.png`} />
      </Helmet>
      <Flex
        direction="column"
        maxWidth="container.xl"
        minHeight="100vh"
        mx="auto"
        px={{ base: 5, md: 10 }}
        transition="padding 168ms ease-in-out"
      >
        <Header />
        <Box flex={1}>
          <Switch>
            <Route
              exact={true}
              path="/login"
              component={LoginPage}
            />

            <Route
              exact={true}
              path="/logout"
              component={LogoutPage}
            />

            <Route
              exact={true}
              path="/signup"
              component={SignupPage}
            />

            <Route
              exact={false}
              path="/password"
              component={PasswordPage}
            />

            <Route
              exact={true}
              path="/privacy"
              component={PrivacyPage}
            />
            
            <Route
              exact={true}
              path="/terms"
              component={TermsPage}
            />

            <PrivateRoute
              exact={false}
              path="/dashboard"
            >
              <DashboardPage />
            </PrivateRoute>
            
            <PrivateRoute
              exact={false}
              path="/settings"
            >
              <SettingsPage />
            </PrivateRoute>

            <Route
              exact={true}
              path="/"
              component={HomePage}
            />

            <Route path="*" component={NotFoundPage} />
          </Switch>
        </Box>
        <Footer />
      </Flex>
    </>
  )
})

export default App

import React, { memo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { 
  Alert,
  AlertTitle,
  Button,
  Icon,
  useColorModeValue
} from '@chakra-ui/react'

import { FiAlertCircle, FiCalendar, FiClock } from 'react-icons/fi'

import { useAuth } from 'components/api'

const TrialNoticeBanner = memo(() => {
  const { currentUser, requestPortalRedirect } = useAuth()
  const history = useHistory()

  const activeTrialColor = useColorModeValue('primary.600', 'white')
  const expiredTrialColor = useColorModeValue('pink.500', 'pink.300')
  
  const { 
    status = "past_due",
    trial_days_remaining = 0,
  }  = currentUser?.subscription || {}

  useEffect(() => {
    if (history.location.hash === '#upgrade' || history.location.state?.prompt === 'upgrade') {
      requestPortalRedirect()
    }
  }, [])

  if (status === "active") {
    return <></>
  }

  return (
    <Alert
      alignItems="center"
      bgColor="transparent"
      borderColor={trial_days_remaining < 3 && expiredTrialColor}
      borderWidth={1}
      color={trial_days_remaining >= 3 ? activeTrialColor : expiredTrialColor}
      fontSize="sm"
      mb={6}
      px={2}
      py={2}
      pl={4}
      status={trial_days_remaining < 2 ? 'warning' : 'info'}
    >
      {trial_days_remaining <= 0 ? (
        <Icon as={FiAlertCircle} boxSize={4} />
      ) : trial_days_remaining < 5 ? (
        <Icon as={FiClock} boxSize={4} />
      ) : (
        <Icon as={FiCalendar} boxSize={4} />
      )}
      <AlertTitle flex={1} ml={2}>
        {trial_days_remaining <= 0 ? (
          `Please upgrade to continue using TabsieBot`
        ) : (
          `${trial_days_remaining} days remaining on your trial`
        )}
      </AlertTitle>
      <Button
        colorScheme={trial_days_remaining >= 3 ? "gray" : expiredTrialColor}
        onClick={requestPortalRedirect}
        size="sm"
        variant="outline"
      >
        Upgrade
      </Button>
    </Alert>
  )
})

export { TrialNoticeBanner }
import React, { memo, useMemo } from 'react'
import Helmet from 'react-helmet'

import { 
  Box,
  Flex,
  Heading,
  Text
} from '@chakra-ui/react'

import { 
  useAuth,
  useFetchTabs,
  useFetchTabRecords
} from 'components/api'

import { 
  BarChart,
  TrialNoticeBanner,
  VerifyDeviceModal
} from 'components/elements'

import { TabRecordRow } from './components/tab-record-row'

const DashboardPage = memo(() => {
  const { currentUser } = useAuth()

  const { data: tabs, refetch } = useFetchTabs()
  const tabId = tabs?.data[0]?.id

  const { data: tabRecord } = useFetchTabRecords(tabId)
  const tabRecordData = tabRecord?.data || []
  const { report } = tabRecord?.meta || {}

  const accountIsActive = useMemo(() => {
    const { status } = currentUser?.subscription || {}
    return ['active', 'trialing'].includes(status)
  }, [currentUser, tabRecord])

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Box maxWidth="lg" mx="auto" pt={{ base: 4, md: 12}}>
        <Heading as="h1" mb={8} size="xl">
          {currentUser && currentUser.first_name ? (
            `Hi, ${currentUser.first_name}!`
          ): (
            `Dashboard`
          )}
        </Heading>

        {currentUser?.unconfirmed_mobile && (
          <VerifyDeviceModal />
        )}

        {!!report && accountIsActive && (
          <>
            {report.streak > 1 && (
              <Box bg="pink.500" borderRadius="md" mb={4} py={14}>
                <Text color="white" fontSize="xl" fontWeight="bold" textAlign="center">
                {`🔥 Current Streak: ${report.streak} days! 🔥`}
                </Text>
              </Box>
            )}
            {report.nonzero_days > 0 && tabRecordData?.length > 0 && (
              <BarChart data={tabRecordData.slice().map(t => t.attributes)} />
            )}
            <Flex justify="space-between" mb={4}>
              <Flex borderRadius="md" borderWidth={1} direction="column" flex={1} mr={2} py={3}>
                <Text textAlign="center">7 Day Average</Text>
                <Heading my={2} size="2xl" textAlign="center">
                  {report.average_7_days}
                </Heading>
              </Flex>
              <Flex borderRadius="md" borderWidth={1} direction="column" flex={1} ml={2} py={3}>
                <Text textAlign="center">28 Day Average</Text>
                <Heading my={2} size="2xl" textAlign="center">
                  {report.average_28_days}
                </Heading>
              </Flex>
            </Flex>
          </>
        )}

        <TrialNoticeBanner />

        {tabRecordData?.length > 0 && accountIsActive && (
          <Box as="table" fontSize="sm" mb={8} mt={4} w="full">
            <thead>
              <tr>
                <Box as="th" py={2} textAlign="left">
                  Last 28 Days
                </Box>
                <Box as="th" py={2} textAlign="center" w="128px">
                  Value
                </Box>
              </tr>
            </thead>
            <tbody>
              {tabRecordData.slice().reverse().map((record, index) => (
                <TabRecordRow
                  key={index}
                  onSuccess={refetch}
                  rowIndex={index}
                  tabId={tabId}
                  tabRecord={record.attributes}
                />
              ))}
            </tbody>
          </Box>
        )}
      </Box>
    </>
  )
})

export default DashboardPage
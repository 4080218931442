import React, { memo, useCallback, useMemo, useState } from 'react'
import { format } from 'date-fns'

import { 
  Box,
  Flex,
  IconButton,
  Input
} from '@chakra-ui/react'

import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi'
import { useUpdateTabRecord } from 'components/api'

export const TabRecordRow = memo(({ onSuccess, rowIndex, tabId, tabRecord = {} }) => {
  const updateMutation = useUpdateTabRecord(tabId, tabRecord.id)
  const [ value, setValue ] = useState(tabRecord.value)

  const label = useMemo(() => (
    format(new Date(tabRecord.timestamp), 'E, MMM d')
  ), [tabRecord])

  const onChange = useCallback(value => {
    const parsed = parseInt(value)
    let newValue = 0
    if (!isNaN(parsed) && parsed > 0 && parsed !== null) {
      newValue = parsed
    }
    setValue(newValue)
    updateMutation
      .mutate({ value: newValue }, {
        onSuccess: () => onSuccess()
      })
  }, [onSuccess])

  const onFocus = useCallback(e => {
    const el = e.target
    setTimeout(() => el.select(), 0)
  }, [])

  const onDecrementValue = useCallback(() => {
    onChange(value - 1)
  }, [value, onChange])
  
  const onIncrementValue = useCallback(() => {
    onChange(value + 1)
  }, [value, onChange])

  const isEditable = rowIndex < 5 || value === null
  const isNumeric = typeof value === 'number'

  return (
    <Box as="tr">
      <Box
        as="td"
        borderTopWidth={rowIndex === 0 ? 4 : 1}
        py={0}
        textAlign="left"
        verticalAlign="middle"
      >
        {label}
      </Box>
      <Box
        as="td"
        borderTopWidth={rowIndex === 0 ? 4 : 1}
        py={isEditable ? 1 : 2}
        textAlign="center"
        verticalAlign="middle"
      >
        {isEditable ? (
          <Flex align="center" justify="center">
            {isNumeric && (
              <IconButton
                aria-label="Decrease by 1"
                colorScheme="gray"
                icon={<FiMinusCircle />}
                isDisabled={!value}
                onClick={onDecrementValue}
                size="sm"
                variant="ghost"
              />
            )}
            <Input
              flex={1}
              mx={1}
              onChange={e => onChange(e.target.value)}
              onFocus={onFocus}
              placeholder="0"
              size="sm"
              textAlign="center"
              type="number"
              value={isNumeric ? value : ''}
              maxWidth={14}
            />
            {isEditable && isNumeric && (
              <IconButton
                aria-label="Increase by 1"
                colorScheme="gray"
                icon={<FiPlusCircle />}
                onClick={onIncrementValue}
                size="sm"
                variant="ghost"
              />
            )}
          </Flex>
        ) : (
          value
        )}
      </Box>
    </Box>
  )
})
const Alert = {
  parts: ['container'],
  baseStyle: {
    container: {
      alignItems: "flex-start",
      borderRadius: "md"
    }
  }
}

const Button = {}

const Checkbox = {
  baseStyle: ({ colorMode }) => ({
    control: {
      _focus: {
        borderColor: 'primary.400'
      }
    }
  }),
  defaultProps: {
    focusBorderColor: 'primary.400'
  }
}

const Input = {
  baseStyle: ({ colorMode }) => ({
    field: {
      _autofill: ({ colors }) => ({
        borderColor: `${colorMode === 'dark' ? colors.primary['500'] : colors.primary['400']} !important`,
        borderWidth: '1px',
        boxShadow: `0 0 0 1px ${colorMode === 'dark' ? colors.primary['500'] : colors.primary['400']}`,
        WebkitBoxShadow: `0 0 0px 1000px ${colorMode === 'dark' ? colors.primary['900'] : colors.primary['50']} inset  !important`,
        WebkitTextFillColor: colorMode === 'dark' ? colors.white : colors.gray['700'],
      }),
      '&:-webkit-autofill::first-line': ({ fonts, fontSizes }) => ({
        fontFamily: fonts.body,
        fontSize: fontSizes.md
      })
    }
  }),
  defaultProps: {
    focusBorderColor: 'primary.400'
  },
}

const Link = {
  baseStyle: ({ colorMode }) => ({
    color: colorMode === 'dark' ? 'primary.200' : 'primary.500',
    _focus: {
      boxShadow: 'none',
      color: colorMode === 'dark' ? 'primary.400' : 'primary.700',
    },
    _hover: {
      color: colorMode === 'dark' ? 'primary.400' : 'primary.700',
      textDecoration: 'none'
    }
  })
}

const Modal = {
  parts: ['body', 'closeButton', 'footer', 'header'],
  baseStyle: {
    closeButton: {
      insetEnd: 2,
      top: 2
    },
    header: {
      pt: 6,
      pb: 0
    },
    body: {
      py: 6
    },
    footer: {
      pt: 0,
      pb: 6
    }
  }
}

const Select = {
  defaultProps: {
    focusBorderColor: 'primary.400'
  }
}

const Textarea = {
  defaultProps: {
    focusBorderColor: 'primary.400'
  }
}

export const components = {
  Alert,
  Button,
  Checkbox,
  Input,
  Link,
  Modal,
  Select,
  Textarea
}
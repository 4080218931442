import React, { memo } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { 
  Box,
  Link,
  Text
} from '@chakra-ui/react'

const Footer = memo(() => {
  return (
    <Box pb={4} pt={10} textAlign="center">
      <Text fontSize="xs" fontWeight="semibold">
        &copy; {new Date().getFullYear()} TabsieBot
        {` • `}
        <Link
          as={RouterLink}
          fontWeight="semibold"
          to="/terms"
        >
          Terms
        </Link>
        {` • `}
        <Link
          as={RouterLink}
          fontWeight="semibold"
          to="/privacy"
        >
          Privacy
        </Link>
      </Text>
    </Box>
  )
})

export { Footer }
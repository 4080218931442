import React, { memo, useCallback, useState } from 'react'
import Helmet from 'react-helmet'

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Heading
} from '@chakra-ui/react'

import {
  EmailForm,
  Spinner
} from 'components/elements'

import { useAuth } from 'components/api'

const PasswordRecoveryPage = memo(() => {
  const { requestPasswordRecovery } = useAuth()
  const [ errors, setErrors ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ success, setSuccess ] = useState(false)
  const [ values, setValues ] = useState({})

  const onClearError = useCallback(index => {
    setErrors(prev => [ ...prev ].filter((e, i) => i !== index))
  }, [errors])

  const onSubmit = useCallback(payload => {
    setLoading(true)
    setValues(payload)
    requestPasswordRecovery(payload)
      .then(() => {
        setSuccess(true)
        setErrors([])
        setLoading(false)
      })
      .catch(err => {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors)
        }
        setSuccess(false)
        setLoading(false)
      })
  }, [])

  const pageTitle = `Password recovery`

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box 
        pt={{ base: 0, md: 16 }}
        maxWidth="sm"
        mx="auto"
        transition="padding 168ms ease-in-out"
      >
        <Heading as="h1" size="xl">
          {pageTitle}
        </Heading>

        {success && (
          <Alert fontSize="sm" mb={2} status="success">
            <AlertIcon />
            <AlertTitle mr={2}>
              Check your inbox for a password reset email
            </AlertTitle>
          </Alert>
        )}

        {errors.length > 0 && (
          <Box mb={4}>
            {errors.map((error, i) => (
              <Alert fontSize="sm" key={i} mb={2} status="error">
                <AlertIcon />
                <AlertTitle mr={2}>{error}</AlertTitle>
                <CloseButton 
                  onClick={() => onClearError(i)}
                  position="absolute"
                  right="8px"
                  top="8px"
                />
              </Alert>
            ))}
          </Box>
        )}

        {loading && (
          <Spinner />
        )}

        {!loading && !success && (
          <EmailForm
            onSubmit={onSubmit} 
            values={values} 
          />
        )}
      </Box>
    </>
  )
})

export default PasswordRecoveryPage
import React, { memo, useCallback, useEffect, useState } from 'react'
import Helmet from 'react-helmet'

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Heading
} from '@chakra-ui/react'

import {
  LoginForm,
  Spinner
} from 'components/elements'

import { useAuth } from 'components/api'

const LoginPage = memo(({ history }) => {
  const { loginUser } = useAuth()
  const [ errors, setErrors ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ notifyConfirmationSuccess, setNotifyConfirmationSuccess ] = useState(false)
  const [ notifyPasswordReset, setNotifyPasswordReset ] = useState(false)
  const [ values, setValues ] = useState({})

  const onClearError = useCallback(index => {
    setErrors(prev => [ ...prev ].filter((e, i) => i !== index))
  }, [errors])

  const onSubmit = useCallback(payload => {
    setLoading(true)
    setValues(payload)
    loginUser(payload)
      .then(() => {
        history.push('/dashboard')
      })
      .catch(err => {
        if (err.response.data.errors) {
          setErrors(err.response.data.errors)
        }
        setLoading(false)
      })
  }, [])

  const pageTitle = `Log in`

  useEffect(() => {
    if (history.location.search === '?account_confirmation_success=true') {
      history.replace(history.location.pathname, undefined)
      setNotifyConfirmationSuccess(true)
    }
    if (history.location.hash === '#password-reset-successful' || history.location.state?.prompt === 'password-reset-successful') {
      history.replace(history.location.pathname, undefined)
      setNotifyPasswordReset(true)
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box 
        pt={{ base: 0, md: 16 }}
        maxWidth="sm"
        mx="auto"
        transition="padding 168ms ease-in-out"
      >
        <Heading as="h1" size="xl">{pageTitle}</Heading>

        {notifyConfirmationSuccess && (
          <Alert fontSize="sm" mb={6} status="success">
            <AlertIcon />
            <AlertTitle mr={2}>
              Your email address has been confirmed.
            </AlertTitle>
          </Alert>
        )}

        {notifyPasswordReset && (
          <Alert fontSize="sm" mb={6} status="success">
            <AlertIcon />
            <AlertTitle mr={2}>
              Your password has been changed. Please log in with your new credentials.
            </AlertTitle>
          </Alert>
        )}

        {errors.length > 0 && (
          <Box mb={4}>
            {errors.map((error, i) => (
              <Alert fontSize="sm" key={i} mb={2} status="error">
                <AlertIcon />
                <AlertTitle mr={2}>{error}</AlertTitle>
                <CloseButton 
                  onClick={() => onClearError(i)}
                  position="absolute"
                  right="8px"
                  top="8px"
                />
              </Alert>
            ))}
          </Box>
        )}
        {loading ? (
          <Spinner />
        ) : (
          <LoginForm
            onSubmit={onSubmit} 
            values={values} 
          />
        )}
      </Box>
    </>
  )
})

export default LoginPage
import React, { memo } from 'react'
import { 
  Box,
  Flex,
  Text,
  useColorMode
} from '@chakra-ui/react'

const PlanDetailBanner = memo(() => {
  const { colorMode } = useColorMode()

  return (
    <Box
      borderRadius="md"
      borderWidth={1}
      color={colorMode === 'dark' ? 'white' : 'primary.700'}
      mb={7}
      px={4}
      py={3}
    >
      <Flex
        align="center"
        justify="space-between"
        mb={1}
      >
        <Box>
          <Text fontWeight="bold">
            Monthly Subscription
          </Text>
        </Box>
        <Box>
          <Text fontWeight="bold">
            $2.99/mo
          </Text>
        </Box>
      </Flex>
      <Text
        color={colorMode === 'dark' ? 'gray.200' : 'gray.600'}
        fontSize="xs"
        lineHeight={5}
      >
        14 day trial – No credit card required to start.
      </Text>
    </Box>
  )
})

export { PlanDetailBanner }
import React, { memo, useCallback } from 'react'
import { Formik, Form, Field } from 'formik'
import { Link as RouterLink } from 'react-router-dom'

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Link,
  Select
} from '@chakra-ui/react'

import { InquiryIntervalInput } from 'components/elements'

export const TabSettingsForm = memo(({ onSubmit, values }) => {
  const {
    inquiry_interval = '',
    status = ''
  } = values

  let initialValues = {
    inquiry_interval,
    status
  }

  const onFormSubmit = useCallback((values, { setSubmitting }) => {
    setSubmitting(false)
    onSubmit(values)
  }, [onSubmit])

  const onValidate = useCallback(values => {
    const errors = {}
    if (!values.inquiry_interval) {
      errors.email = 'Daily message time is required'
    }
    return errors
  }, [])

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validate={onValidate}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>

          {/* Inquiry Interval */}
          <Field name="inquiry_interval">
            {({ field, form }) => (
              <FormControl
                isDisabled={isSubmitting}
                isInvalid={
                  form.errors.inquiry_interval &&
                  form.touched.inquiry_interval
                }
                isRequired={true}
                mb={4}
              >
                <FormLabel>
                  Daily Message Time
                </FormLabel>
                <InquiryIntervalInput
                  {...field}
                  onChange={value => setFieldValue('inquiry_interval', value)}
                />
                <FormErrorMessage>
                  {form.errors.inquiry_interval}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          {/* Status */}
          <Field name="status">
            {({ field, form }) => (
              <FormControl
                isDisabled={isSubmitting}
                isInvalid={
                  form.errors.status &&
                  form.touched.status
                }
                isRequired={true}
                mb={4}
              >
                <FormLabel>
                  Status
                </FormLabel>
                <Select {...field}>
                  <option value="active">Active</option>
                  <option value="paused">Paused</option>
                </Select>
                {field.value !== 'active' && (
                  <FormHelperText lineHeight={5}>
                    {`Messaging `}
                    {status !== 'active' ? 'is' : 'will be'}
                    {` paused, but daily tab records `}
                    {status !== 'active' ? 'are still' : 'will still be'}
                    {` editable from the `}
                    <Link
                      as={RouterLink}
                      to="/dashboard"
                    >
                      Dashboard
                    </Link>
                    {`.`}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </Field>

          <Button
            isDisabled={isSubmitting}
            mt={4}
            type="submit"
            variant="outline"
          >
            Update
          </Button>
        </Form>
      )}
    </Formik>
  )
})
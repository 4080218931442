import { 
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query'
import { api } from './client'
import { useAnalytics } from './analytics-context'

const FETCH_USER_KEY = 'fetch-user'

export const useFetchUser = (id) => {
  return useQuery(
    FETCH_USER_KEY,
    async () => {
      const response = await api.get(`/users/${id}`)
      return response?.data
    }, 
    {
      enabled: !!id
    }
  )
}

export const useUpdateUser = (id) => {
  const queryClient = useQueryClient()
  const { trackEvent } = useAnalytics()
  return useMutation(
    async (payload) => {
      const response = await api.put(`/users/${id}`, payload)
      return response?.data
    }, 
    {
      onSuccess: (res) => {
        trackEvent('user-update', { props: { userId: id } })
        return queryClient.setQueryData(FETCH_USER_KEY, res)
      },
      onError: () => {
        trackEvent('error', { props: { event: 'user-update' } })
      }
    }
  )
}

export const useDeleteUser = (id) => {
  const queryClient = useQueryClient()
  const { trackEvent } = useAnalytics()
  return useMutation(
    () => api.delete(`/users/${id}`),
    {
      onSettled: () => {
        queryClient.invalidateQueries()
        trackEvent('user-deletion', { props: { userId: id } })
      }
    }
  )
}
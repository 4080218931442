import React, { memo, useCallback } from 'react'
import { Formik, Form, Field } from 'formik'

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input
} from '@chakra-ui/react'

export const PasswordForm = memo(({ onSubmit, values }) => {
  const {
    password = '',
    password_confirmation = ''
  } = values

  let initialValues = {
    password,
    password_confirmation
  }

  const onFormSubmit = useCallback((values, { setSubmitting, resetForm }) => {
    setSubmitting(false)
    resetForm()
    onSubmit(values)
  }, [onSubmit])

  const onValidate = useCallback(values => {
    const errors = {}
    if (!values.password) {
      errors.password = 'Password is required'
    }
    if (values.password && values.password.length < 6) {
      errors.password = 'Passwords must contain at least 6 characters'
    }
    if (!values.password_confirmation) {
      errors.password_confirmation = 'Password confirmation is required'
    } else if (values.password_confirmation !== values.password) {
      errors.password_confirmation = 'Passwords must match'
    }
    return errors
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validate={onValidate}
    >
      {({ isSubmitting }) => (
        <Form>
          {/* Password */}
          <Field name="password">
            {({ field, form }) => (
              <FormControl
                isDisabled={isSubmitting}
                isInvalid={
                  form.errors.password &&
                    (form.touched.password ||
                      form.touched.password_confirmation)
                }
                isRequired={true}
                mb={4}
              >
                <FormLabel>
                  Password
                </FormLabel>
                <Input
                  {...field}
                  placeholder="••••••••••••"
                  type="password"
                />
                <FormErrorMessage>
                  {form.errors.password}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          {/* Password Confirmation */}
          <Field name="password_confirmation">
            {({ field, form }) => (
              <FormControl
                isDisabled={isSubmitting}
                isInvalid={
                  form.errors.password_confirmation &&
                  form.touched.password_confirmation
                }
                isRequired={true}
                mb={4}
              >
                <FormLabel>
                  Confirm Password
                </FormLabel>
                <Input
                  {...field}
                  placeholder="••••••••••••"
                  type="password"
                />
                <FormErrorMessage>
                  {form.errors.password_confirmation}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Button
            isDisabled={isSubmitting}
            mt={4}
            type="submit"
            variant="outline"
          >
            Update
          </Button>
        </Form>
      )}
    </Formik>
  )
})
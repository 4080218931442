import React, { memo, useEffect } from 'react'
import Helmet from 'react-helmet'
import { QueryCache } from 'react-query'

import { Spinner } from 'components/elements'
import { useAuth } from 'components/api'

const Logout = memo(({ history }) => {
  const { logoutUser } = useAuth()
  const queryCache = new QueryCache()

  useEffect(() => {
    logoutUser()
      .then(() => queryCache.clear())
      .then(() => history.push('/'))
      .catch(() => history.push('/'))
  }, [])

  return (
    <>
      <Helmet>
        <title>Logging out&hellip;</title>
      </Helmet>
      <Spinner py={24} />
    </>
  )
})

export default Logout
import React, { memo, useCallback } from 'react'
import { Formik, Form, Field } from 'formik'

import {
  Button,
  HStack,
  PinInput,
  PinInputField,
  useColorModeValue,
  useToken
} from '@chakra-ui/react'

export const VerifyForm = memo(({ onSubmit, values }) => {
  const [gray50, gray800] = useToken('colors', ['gray.50', 'gray.800'])
  const bgColor = useColorModeValue(gray50, gray800)

  const onFormSubmit = useCallback((values, { setSubmitting }) => {
    setSubmitting(false)
    onSubmit(values)
  }, [onSubmit])

  return (
    <Formik
      initialValues={{ token: '' }}
      onSubmit={onFormSubmit}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <Field name="token">
            {({ field, form }) => (
              <>
                <HStack 
                  bgColor={bgColor}
                  borderRadius="lg"
                  justify="center"
                  mb={6}
                  py={5}
                  spacing={3}
                >
                  <PinInput
                    autoFocus={true}
                    isDisabled={isSubmitting}
                    isInvalid={form.errors.token && form.touched.token}
                    isRequired={true}
                    onChange={value => setFieldValue('token', value)}
                    otp={true}
                    size="lg"
                    value={field.value}
                  >
                    <PinInputField borderColor="gray.400" fontWeight="bold" _focus={{ borderColor: "primary.400" }} />
                    <PinInputField borderColor="gray.400" fontWeight="bold" _focus={{ borderColor: "primary.400" }} />
                    <PinInputField borderColor="gray.400" fontWeight="bold" _focus={{ borderColor: "primary.400" }} />
                    <PinInputField borderColor="gray.400" fontWeight="bold" _focus={{ borderColor: "primary.400" }} />
                    <PinInputField borderColor="gray.400" fontWeight="bold" _focus={{ borderColor: "primary.400" }} />
                  </PinInput>
                </HStack>
              </>
            )}
          </Field>
          <Button
            colorScheme={values.token.length < 5 ? "gray" : "teal"}
            isDisabled={
              isSubmitting || values.token.length < 5
            }
            type="submit"
            w="full"
          >
            Complete Setup
          </Button>
        </Form>
      )}
    </Formik>
  )
})

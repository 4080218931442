import React, { memo, useCallback } from 'react'
import { Formik, Form, Field } from 'formik'

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input
} from '@chakra-ui/react'

import { TimezoneSelect } from 'components/elements'

export const UserProfileForm = memo(({ onSubmit, values }) => {
  const {
    first_name = '',
    last_name = '',
    timezone = ''
  } = values

  let initialValues = {
    first_name,
    last_name,
    timezone
  }

  const onFormSubmit = useCallback((values, { setSubmitting }) => {
    setSubmitting(false)
    onSubmit(values)
  }, [onSubmit])

  const onValidate = useCallback(values => {
    const errors = {}
    if (!values.first_name) {
      errors.first_name = 'First name is required'
    }
    return errors
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validate={onValidate}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form>

          <Flex justify="space-between" mb={4}>
            <Box flex={1} mr={2}>
              {/* First Name */}
              <Field name="first_name">
                {({ field, form }) => (
                  <FormControl
                    isDisabled={isSubmitting}
                    isInvalid={
                      form.errors.first_name &&
                      form.touched.first_name
                    }
                    isRequired={true}
                  >
                    <FormLabel>
                      First Name
                    </FormLabel>
                    <Input {...field}/>
                    <FormErrorMessage>
                      {form.errors.first_name}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Box>

            <Box flex={1} ml={2}>
              {/* Last Name */}
              <Field name="last_name">
                {({ field, form }) => (
                  <FormControl
                    isDisabled={isSubmitting}
                    isInvalid={
                      form.errors.last_name &&
                      form.touched.last_name
                    }
                    isRequired={false}
                  >
                    <FormLabel>
                      Last Name
                    </FormLabel>
                    <Input {...field}/>
                    <FormErrorMessage>
                      {form.errors.last_name}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Box>
          </Flex>

          {/* Timezone */}
          <Field name="timezone">
            {({ field, form }) => (
              <FormControl
                isDisabled={isSubmitting}
                isInvalid={form.errors.timezone && form.touched.timezone}
                isRequired={false}
                mb={4}
              >
                <FormLabel>
                  Timezone
                </FormLabel>
                <TimezoneSelect
                  {...field}
                  onChange={e => setFieldValue('timezone', e.target.value)}
                  placeholder="Select a timezone"
                  shouldGuessTimezone={false}
                />
                <FormErrorMessage>
                  {form.errors.timezone}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Button
            isDisabled={isSubmitting}
            mt={4}
            type="submit"
            variant="outline"
          >
            Update
          </Button>
        </Form>
      )}
    </Formik>
  )
})
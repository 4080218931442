import React, { memo, useCallback, useEffect, useState } from 'react'
import parsePhoneNumber, { AsYouType, isValidPhoneNumber } from 'libphonenumber-js'
import ReactCountryFlag from 'react-country-flag'

import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Select
} from '@chakra-ui/react'
import { PhoneIcon } from '@chakra-ui/icons'

import { ALLOWED, getCountry } from './countries'

export const PhoneNumberInput = memo((props) => {
  const { 
    country,
    onChange,
    placeholder,
    size = 'md',
    value,
    ...rest
  } = props

  const [inputValue, setInputValue] = useState('')
  const [selectedCountry, setSelectedCountry] = useState({})

  useEffect(() => {
    if (value && isValidPhoneNumber(value)) {
      setInputValue(parsePhoneNumber(value).formatNational())
    }
  }, [])

  useEffect(() => {
    const match = getCountry(country)
    if (match) {
      setSelectedCountry(match)
    }
  }, [country])

  const onChangeCountry = useCallback((str = "") => {
    const newCountry = getCountry(str)
    if (newCountry) {
      setSelectedCountry(newCountry)
      setInputValue('')
      onChange('')
    }
  }, [])

  const onChangePhoneNumber = useCallback((str = "") => {
    if (str.length > 0) {
      const field = new AsYouType(selectedCountry.iso2)
      field.input(str)
      const number = field.getNumber()
      if (number) {
        const plain = number.number
        const pretty = new AsYouType(selectedCountry.iso2).input(number.nationalNumber)
        if (pretty.length <= 5 || pretty.substring(pretty.length-1) !== ')') {
          setInputValue(pretty)
        } else {
          setInputValue(plain)
        }
        onChange(plain)
      }
      else {
        setInputValue(str)
        onChange(`${selectedCountry.prefix}${str}`)
      }
    }
    else {
      setInputValue('')
      onChange('')
    }
  }, [selectedCountry])

  return (
    <InputGroup size={size} {...rest}>
      <InputLeftAddon
        px={0}
        position="relative"
        w="4.5rem"
      >
        <Select
          bottom={0}
          cursor="pointer"
          display="flex"
          id="country-selector"
          left={0}
          onChange={e => onChangeCountry(e.target.value)}
          opacity={0}
          p={0}
          position="absolute"
          right={0}
          top={0}
          value={selectedCountry.iso2}
          zIndex={1}
        >
          {ALLOWED.map((option, index) => (
            <option
              key={index}
              value={option.iso2}
            >
              {`${option.name} (${option.prefix})`}
            </option>
          ))}
        </Select>
        <Flex 
          alignItems="center"
          fontSize="3xl"
          pl={3}
          w="full"
        >
          {selectedCountry.iso2 ? (
            <ReactCountryFlag 
              aria-label="United States"
              countryCode={selectedCountry.iso2 || ''}
            />
          ) : (
            <PhoneIcon />
          )}
        </Flex>
      </InputLeftAddon>
      <Input
        onChange={e => onChangePhoneNumber(e.target.value)}
        placeholder={placeholder}
        type="tel"
        value={inputValue}
      />
    </InputGroup>
  )
})
import React, { memo } from 'react'
import { BrowserRouter, StaticRouter } from 'react-router-dom'

export default memo(props => (
  <>
    {typeof window === 'undefined' || typeof document === 'undefined' ? (
      <StaticRouter context={{}} {...props} />
    ) : (
      <BrowserRouter {...props} />
    )}
  </>
))
import React, { memo, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { 
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'

import {
  VerifyForm,
  Spinner
} from 'components/elements'

import { useAuth } from 'components/api'

const VerifyDeviceModal = memo(() => {
  const { verifyUserDevice } = useAuth()
  const history = useHistory()
  const toast = useToast()

  const [ errors, setErrors ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ values, setValues ] = useState({})

  const { isOpen, onOpen, onClose } = useDisclosure()

  const onClearError = useCallback(index => {
    setErrors(prev => [ ...prev ].filter((e, i) => i !== index))
  }, [errors])
  
  const onSubmit = useCallback(payload => {
    const mobile = localStorage.getItem('verify-mobile-number') || ''
    const request = { ...payload, mobile }
    setErrors([])
    setLoading(true)
    setValues(request)
    verifyUserDevice(request)
      .then(() => {
        onClose()
        toast({
          duration: 5000,
          isClosable: true,
          position: 'top',
          title: 'Mobile device verified',
          status: 'success'
        })
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.error)
        setErrors([
          err.response.data.error || 'Verification Failed'
        ])
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (history.location.hash === '#verify-device' || history.location.state?.prompt === 'verify-device') {
      onOpen()
      history.replace(history.location.pathname, undefined)
    }
  }, [])

  return (
    <>
      <Alert alignItems="center" mb={6} status="info">
        <AlertIcon />
        <AlertTitle flex={1}>
          Verify your mobile device
        </AlertTitle>
        <Button
          onClick={onOpen}
          size="sm"
        >
          Verify
        </Button>
      </Alert>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Verify your device
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="sm" mb={6}>
              We sent a verification token to the mobile number you provided. Enter the token below.
            </Text>
            {errors.length > 0 && (
              <Box mb={4}>
                {errors.map((error, i) => (
                  <Alert alignItems="flex-start" fontSize="sm" key={i} mb={4} pr={12} status="error">
                    <AlertIcon />
                    <AlertTitle mr={2}>{error}</AlertTitle>
                    <CloseButton 
                      onClick={() => onClearError(i)}
                      position="absolute"
                      right="8px"
                      top="8px"
                    />
                  </Alert>
                ))}
              </Box>
            )}
            {loading ? (
              <Spinner />
            ) : (
              <VerifyForm
                onSubmit={onSubmit} 
                values={values} 
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
})

export { VerifyDeviceModal }
import React, { memo } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import PasswordRecoveryPage from './recovery'
import PasswordResetPage from './reset'

const PasswordPage = memo(({ history }) => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${path}/recovery`} component={PasswordRecoveryPage} />
      <Route exact path={`${path}/reset`} component={PasswordResetPage} />
      <Redirect from={path} to="/login" />
    </Switch>
  )
})

export default PasswordPage
import React, { memo, useCallback } from 'react'
import { Formik, Form, Field } from 'formik'

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input
} from '@chakra-ui/react'

export const EmailForm = memo(({ onSubmit, values }) => {
  const {
    email = ''
  } = values

  let initialValues = {
    email
  }

  const onFormSubmit = useCallback((values, { setSubmitting }) => {
    setSubmitting(false)
    onSubmit(values)
  }, [onSubmit])

  const onValidate = useCallback(values => {
    const errors = {}
    if (!values.email) {
      errors.email = 'Email address is required'
    }
    return errors
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validate={onValidate}
    >
      {({ isSubmitting }) => (
        <Form>
          {/* Email Address */}
          <Field name="email">
            {({ field, form }) => (
              <FormControl
                isDisabled={isSubmitting}
                isInvalid={form.errors.email && form.touched.email}
                isRequired={true}
                mb={8}
              >
                <FormLabel>
                  Email Address
                </FormLabel>
                <Input
                  {...field}
                  placeholder="example@address.com"
                />
                <FormErrorMessage>
                  {form.errors.email}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Button
            isDisabled={isSubmitting}
            type="submit"
            variant="solid"
            w="full"
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  )
})
import React, { memo, useCallback, useMemo } from 'react'
import { getTimeZones } from '@vvo/tzdb'
import { Select } from '@chakra-ui/react'
import { formatTimezoneOffset, guessTimezone } from 'components/utils'

const spotlight = [
  'Pacific/Midway',
  'Pacific/Samoa',
  'Pacific/Honolulu',
  'America/Anchorage',
  'America/Los_Angeles',
  'America/Phoenix',
  // 'America/Chihuahua',
  // 'America/Mazatlan',
  'America/Denver',
  'America/Chicago',
  // 'America/Mexico_City',
  // 'America/Monterrey',
  // 'Canada/Saskatchewan',
  'America/New_York',
  'America/Puerto_Rico',
  // 'America/Hallifax',
  // 'America/St_Johns'
]

export const TimezoneSelect = memo(({ shouldGuessTimezone = true, onChange, value, ...rest }) => {
  const timezones = useMemo(() => getTimeZones(), [])

  const topTimezones = useMemo(() => (
    timezones.filter(tz => spotlight.includes(tz.name))
  ), [timezones])

  const onSelectTimezone = useCallback(e => {  
    if (typeof onChange === 'function') {
      onChange(e)
    }
  }, [onChange])

  const selectedTimezone = useMemo(() => {
    const selected = (value) ? value : (shouldGuessTimezone) ? guessTimezone() : ''
    const match = timezones.find(({ name }) => name === selected)
    return match ? match.name : ""
  }, [value])

  return (
    <Select
      display="flex"
      lineHeight={10}
      onChange={onSelectTimezone}
      value={selectedTimezone}
      {...rest}
    >
      <optgroup label="United States">
        {topTimezones?.length > 0 && topTimezones.map((tz, i) => (
          <option key={i} value={tz.name}>
            {`(UTC${formatTimezoneOffset(tz.currentTimeOffsetInMinutes)}) ${tz.mainCities.join(', ')}`}
          </option>
        ))}
      </optgroup>
      <optgroup label="Worldwide">
        {timezones?.length > 0 && timezones.map((tz, i) => (
          <option key={i} value={tz.name}>
            {`(UTC${formatTimezoneOffset(tz.currentTimeOffsetInMinutes)}) ${tz.mainCities.join(', ')}`}
          </option>
        ))}
      </optgroup>
    </Select>
  )
})
import React, { 
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useQueryClient } from 'react-query'

import { api } from './client'
import { useAnalytics } from './analytics-context'

const AuthContext = createContext()
AuthContext.displayName = 'AuthContext'

/* AUTH TOKEN */

function setAuthToken(headers) {
  localStorage.setItem('auth-access-token', headers['access-token'])
  localStorage.setItem('auth-client', headers['client'])
  localStorage.setItem('auth-expiry', headers['expiry'])
  localStorage.setItem('auth-token-type', headers['token-type'])
  localStorage.setItem('auth-uid', headers['uid'])
}

function clearAuthToken() {
  localStorage.setItem('auth-access-token', '')
  localStorage.setItem('auth-client', '')
  localStorage.setItem('auth-expiry', '')
  localStorage.setItem('auth-token-type', '')
  localStorage.setItem('auth-uid', '')
}

function AuthProvider(props) {
  const [ currentUser, setCurentUser ] = useState({})
  const [ isAuthenticated, setIsAuthenticated ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(true)
  const queryClient = useQueryClient()
  const { trackEvent } = useAnalytics()

  useEffect(() => {
    if (typeof window === 'undefined' || typeof document === 'undefined') {
      return
    }
    const params = new URLSearchParams(window.location.search)
    const token = Object.fromEntries(params.entries())
    if (token && token.account_confirmation_success && token.uid) {
      clearAuthToken()
      setAuthToken(token)
      history.replaceState && history.replaceState(null, '', '?confirmation=true')
    }
    if (localStorage.getItem('auth-access-token')) {
      validateUser()
    }
    else {
      setIsLoading(false)
    }
  }, [])

  const loginUser = useCallback(payload => {
    return new Promise((resolve, reject) => {
      queryClient
        .fetchQuery('login-user', async () => api.post('/auth/sign_in', payload))
        .then(({ headers }) => setAuthToken(headers))
        .then(() => validateUser())
        .then(res => {
          trackEvent('login')
          resolve(res)
        })
        .catch(err => {
          clearAuthToken()
          trackEvent('error', { props: { event: 'login' } })
          reject(err)
        })
    })
  }, [])

  const logoutUser = useCallback(() => {
    return new Promise((resolve, reject) => {
      queryClient
        .fetchQuery('logout-user', async () => api.get('/auth/sign_out'))
        .then(() => clearAuthToken())
        .then(() => setCurentUser({}))
        .then(() => setIsAuthenticated(false))
        .then(resolve)
        .catch(err => {
          clearAuthToken()
          reject(err)
        })
    })
  }, [])

  const requestPasswordRecovery = useCallback(payload => {
    return new Promise((resolve, reject) => {
      queryClient
        .fetchQuery('request-password-reset', async () => api.post('/auth/password', payload))
        .then(res => {
          trackEvent('password-reset-request')
          resolve(res)
        })
        .catch(err => {
          trackEvent('error', { props: { event: 'password-reset-request' } })
          reject(err)
        })
    })
  }, [])

  const requestPortalRedirect = useCallback(() => {
    return new Promise((resolve, reject) => {
      queryClient
        .fetchQuery('request-portal-redirect', async () => api.post('/redirects/portal'))
        .then(res => {
          window.location = res.data['session_url']
          resolve()
        })
        .catch(reject)
    })
  }, [])

  const signupUser = useCallback(payload => {
    return new Promise((resolve, reject) => {
      queryClient
        .fetchQuery('signup-user', async () => api.post('/auth', payload))
        .then(({ headers }) => setAuthToken(headers))
        .then(() => validateUser())
        .then(res => {
          trackEvent('signup')
          resolve(res)
        })
        .catch(err => {
          clearAuthToken()
          trackEvent('error', { props: { event: 'signup' } })
          reject(err)
        })
    })
  }, [])

  const updatePassword = useCallback(payload => {
    return new Promise((resolve, reject) => {
      queryClient
        .fetchQuery('reset-password', async () => api.put('/auth/password', payload))
        .then(res => {
          trackEvent('password-update')
          resolve(res)
        })
        .catch(err => {
          trackEvent('error', { props: { event: 'password-update' } })
          reject(err)
        })
    })
  }, [])

  const validateUser = useCallback(() => {
    return new Promise((resolve, reject) => {
      queryClient
        .fetchQuery('validate-token', async () => api.get('/auth/validate_token'))
        .then(res => {
          setCurentUser(res.data.data.attributes)
          setIsAuthenticated(true)
          setIsLoading(false)
          resolve(res)
        })
        .catch(err => {
          clearAuthToken()
          setIsAuthenticated(false)
          setIsLoading(false)
          reject(err)
        })
    })
  }, [])

  const verifyUserDevice = useCallback(payload => {
    return new Promise((resolve, reject) => {
      queryClient
        .fetchQuery('verify-user', async () => api.post('/auth/verify', payload))
        .then(res => {
          setAuthToken(res.headers)
          queryClient.invalidateQueries('validate-token')
          validateUser()
          trackEvent('device-verification')
          resolve(res)
        })
        .catch(err => {
          trackEvent('error', { props: { event: 'device-verification' } })
          reject(err)
        })
    })
  }, [])

  const value = useMemo(() => ({
    currentUser,
    isAuthenticated,
    isLoading,
    loginUser,
    logoutUser,
    requestPasswordRecovery,
    requestPortalRedirect,
    signupUser,
    updatePassword,
    validateUser,
    verifyUserDevice
  }), [
    currentUser,
    isAuthenticated,
    isLoading,
    loginUser,
    logoutUser,
    requestPasswordRecovery,
    requestPortalRedirect,
    signupUser,
    updatePassword,
    validateUser,
    verifyUserDevice
  ])

  return (
    <AuthContext.Provider value={value} {...props} />
  )
}

function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export {
  AuthProvider,
  useAuth
}
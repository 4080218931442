export const space = {
  'px': '1px',
  '0': '0px',
  '1': '0.25rem',
  '2': '0.50rem',
  '3': '0.75rem',
  '4': '1.00rem',
  '5': '1.25rem',
  '6': '1.50rem',
  '7': '1.75rem',
  '8': '2.00rem',
  '9': '2.25rem',
  '10': '2.50rem',
  '11': '2.75rem',
  '12': '3.00rem',
  '13': '3.25rem',
  '14': '3.5rem',
  '15': '3.75rem',
  '16': '4rem',
  '18': '4.5rem',
  '20': '5rem',
  '24': '6rem',
  '28': '7rem',
  '32': '8rem',
  '36': '9rem',
  '40': '10rem',
  '44': '11rem',
  '48': '12rem',
  '52': '13rem',
  '56': '14rem',
  '60': '15rem',
  '64': '16rem',
  '72': '18rem',
  '80': '20rem',
  '96': '24rem' 
}
import React, { memo, useCallback, useRef } from 'react'
import Helmet from 'react-helmet'
import { Link as RouterLink } from 'react-router-dom'
import { useQueryClient } from 'react-query'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Heading,
  useDisclosure,
  useToast
} from '@chakra-ui/react'

import { FiExternalLink } from 'react-icons/fi'

import {
  useAuth,
  useDeleteUser,
  useFetchTabs,
  useFetchUser,
  useUpdateTab,
  useUpdateUser
} from 'components/api'

import { 
  PasswordForm,
  Spinner,
  TabSettingsForm,
  TrialNoticeBanner,
  UserAccountForm,
  UserProfileForm,
  VerifyDeviceModal
} from 'components/elements'

const SettingsPage = memo(({ history }) => {
  const { currentUser, requestPortalRedirect, updatePassword, validateUser } = useAuth()

  const { data, isLoading } = useFetchUser(currentUser.id)
  const userData = data?.data?.attributes || {}

  const { data: tabs } = useFetchTabs()
  const tabData = tabs?.data[0]?.attributes || {}

  const updateTabMutation = useUpdateTab(tabData.id)
  const updateUserMutation = useUpdateUser(currentUser.id)
  const deleteUserMutation = useDeleteUser(currentUser.id)
  
  const cancelRef = useRef()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const queryClient = useQueryClient()
  const toast = useToast()

  const onDeleteUser = useCallback(() => {
    deleteUserMutation.mutate()
    history.push('/logout')
  }, [])

  const onUpdatePassword = useCallback(payload => {
    updatePassword(payload)
      .then(() => {
        toast({
          duration: 5000,
          isClosable: true,
          position: 'top',
          title: 'Password updated',
          status: 'success'
        })
      })
      .catch(err => console.log(err))
  }, [])
  
  const onUpdateTab = useCallback(payload => {
    updateTabMutation.mutate(payload, {
      onSuccess: () => {
        toast({
          duration: 5000,
          isClosable: true,
          position: 'top',
          title: 'Schedule updated',
          status: 'success'
        })
      }
    })
  }, [])

  const onUpdateUser = useCallback(payload => {
    updateUserMutation.mutate(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries('fetch-tabs')
        queryClient.invalidateQueries('validate-token')
        validateUser()
        toast({
          duration: 5000,
          isClosable: true,
          position: 'top',
          title: 'Settings updated',
          status: 'success'
        })
      }
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Box maxWidth="lg" mx="auto" pt={{ base: 4, md: 12}}>
        <Flex align="center" justify="space-between" mb={8}>
          <Heading as="h1" mb={0} size="xl">
            Settings
          </Heading>
          <Button
            as={RouterLink}
            colorScheme="gray"
            fontSize="sm"
            to="/logout"
          >
            Log out
          </Button>
        </Flex>

        {currentUser && currentUser.unconfirmed_mobile && (
          <VerifyDeviceModal />
        )}

        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <TrialNoticeBanner />

            <Heading as="h2" mb={5} size="lg">
              Account
            </Heading>
            <Box mb={12}>
              <UserAccountForm 
                onSubmit={onUpdateUser}
                values={userData}
              />
            </Box>

            <Box as="hr" />

            <Heading as="h2" mb={5} size="lg">
              Profile
            </Heading>
            <Box mb={12}>
              <UserProfileForm 
                onSubmit={onUpdateUser}
                values={userData}
              />
            </Box>

            <Box as="hr" />

            <Heading as="h2" mb={5} size="lg">
              Schedule
            </Heading>
            <Box mb={12}>
              {tabData && tabData.id && (
                <TabSettingsForm 
                  onSubmit={onUpdateTab}
                  values={tabData}
                />
              )}
            </Box>

            <Box as="hr" />

            {currentUser && currentUser.activation_completed_at && (
              <>
              <Heading as="h2" mb={5} size="lg">
                Subscription
              </Heading>
              <Box mb={12}>
                <Button
                  colorScheme="gray"
                  onClick={requestPortalRedirect}
                  rightIcon={<FiExternalLink />}
                  variant="outline"
                >
                  Manage my subscription
                </Button>
              </Box>
              <Box as="hr" />
              </>
            )}

            <Heading as="h2" mb={5} size="lg">
              Password
            </Heading>
            <Box mb={12}>
              <PasswordForm
                onSubmit={onUpdatePassword}
                values={{}}
              />
            </Box>

            <Box as="hr" />

            <Heading as="h2" mb={5} size="lg">
              Delete Account
            </Heading>
            <Box mb={12}>
              <Button colorScheme="red" onClick={onOpen} size="sm" variant="outline">
                Delete my account
              </Button>
            </Box>

            <AlertDialog
              isCentered={true}
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              motionPreset="slideInBottom"
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Delete Account?
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    This action is immediate and permanent. All data associated with this account will be deleted.
                  </AlertDialogBody>

                  <AlertDialogFooter justifyContent="flex-start">
                    <Button colorScheme="red" onClick={onDeleteUser} mr={2}>
                      Delete
                    </Button>
                    <Button colorScheme="gray" onClick={onClose} ref={cancelRef} variant="ghost">
                      Cancel
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </>
        )}
      </Box>
    </>
  )
})

export default SettingsPage
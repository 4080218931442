export const fontSizes = {
  'xs': '0.8125rem',
  'sm': '0.875rem',
  'md': '0.9375rem',
  'base': '1.000rem',
  'lg': '1.125rem',
  'xl': '1.25rem',
  '2xl': '1.375rem',
  '3xl': '1.5625rem',
  '4xl': '1.8125rem',
  '5xl': '2.0rem',
  '6xl': '2.7500rem',
}

export const fontWeights = {
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
}

export const lineHeights = {
  none: '1',
  normal: 'normal',

  shorter: '1rem',
  short: '1.25rem',
  base: '1.5rem',
  tall: '1.75rem',
  taller: '2rem',

  'xs': '1.5rem',
  'sm': '1.750rem',
  'md': '2.000rem',
  'lg': '2.000rem',

  'xl': '2.0000rem',
  '2xl': '2.2500rem',
  '3xl': '2.5000rem',
  '4xl': '3.2500rem',
}

export const letterSpacings = {
  tighter: '-0.025em',
  tight: '-0.0125em',
  normal: '0',
  wide: '0.025em',
  wider: '0.05em',
  widest: '0.1em',
}
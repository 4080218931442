import React, { memo, useCallback, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { Link as RouterLink } from 'react-router-dom'

import { 
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Heading
} from '@chakra-ui/react'

import {
  PlanDetailBanner,
  SignupForm,
  Spinner
} from 'components/elements'

import { useAuth } from 'components/api'

const SignupPage = memo(({ history }) => {
  const { currentUser, signupUser } = useAuth()
  const [ errors, setErrors ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ values, setValues ] = useState({})

  const onClearError = useCallback(index => {
    setErrors(prev => [ ...prev ].filter((e, i) => i !== index))
  }, [errors])
  
  const onSubmit = useCallback(payload => {
    setErrors([])
    setLoading(true)
    setValues(payload)
    signupUser(payload)
      .then(() => {
        history.push('/dashboard', { prompt: 'verify-device' })
      })
      .catch(err => {
        setErrors([
          err.response.data.error || 'Registration could not be completed.'
        ])
        setLoading(false)
      })
  }, [])

  const pageTitle = `Create an account`

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box
        pt={{ base: 0, md: 16 }}
        maxWidth="sm"
        mx="auto"
        transition="padding 168ms ease-in-out"
      >
        <Heading as="h1" size="xl">{pageTitle}</Heading>
        
        {(currentUser && !!currentUser.id) ? (
          <Alert fontSize="sm" mb={4} status="error">
            <AlertIcon />
            <AlertTitle flex={1} mr={2}>
              {`You are currently signed in.`}
            </AlertTitle>
            <Button as={RouterLink} borderRadius="sm" colorScheme="red" size="xs" to="/logout" variant="outline">Log out</Button>
          </Alert>
        ) : (
          <PlanDetailBanner />
        )}

        {errors.length > 0 && (
          <Box mb={4}>
            {errors.map((error, i) => (
              <Alert alignItems="flex-start" fontSize="sm" key={i} mb={4} pr={12} status="error">
                <AlertIcon />
                <AlertTitle mr={2}>{error}</AlertTitle>
                <CloseButton 
                  onClick={() => onClearError(i)}
                  position="absolute"
                  right="8px"
                  top="8px"
                />
              </Alert>
            ))}
          </Box>
        )}

        {loading ? (
          <Spinner />
        ) : (
          <SignupForm
            onSubmit={onSubmit} 
            values={values} 
          />
        )}
      </Box>
    </>
  )
})

export default SignupPage